import React, { useRef } from 'react'

//Icon
import truckIcon from '../assets/truckIcon.png';
import factoryIcon from '../assets/factoryIcon.png';
import portIcon from '../assets/portIcon.jpg'

//Leaflet
import { MapContainer, Marker, TileLayer, GeoJSON, Popup } from 'react-leaflet';
import { changsha } from '../util/landPlot';
import { Icon } from 'leaflet';
import tilelayer from "../util/tilelayer.js";

//Fetching data
import useSwr from "swr";

const fetcher = (...args) => fetch(...args).then(response => response.json());

const TruckRoute = () => {
  //default positon logic
  const defaultArray = [39.5127203, 150.9807516];

  //Truck Location
  var truckUrl5 = "https://x18tracker.rekstrarlausnir.com/api/v2/land/factory5"
  const { data: truckData5, error: truckError5 } = useSwr(truckUrl5, { fetcher });
  const truckLocation5 = truckData5 && !truckError5 ? truckData5 : defaultArray;
  const truckLat5 = truckData5 && !truckError5 ? truckLocation5.Point[0] : defaultArray[0];
  const truckLon5 = truckData5 && !truckError5 ? truckLocation5.Point[1] : defaultArray[1]
  var truckLoc5 = [truckLon5, truckLat5];

  //Data on the Truck default
  var defaultProduct = {
    "product": [
      {
        "id": 555,
        "name": "Product not ready",
        "category": "loading, not connected, internet problem, error in system",
        "price": 349000,
        "imgurl": "cdn.mattressonline.co.uk/content/products/full/sealy-millionaire-orthopaedic-mattress-full.jpg"
      }
    ]
  }

  const myContainer = useRef(() => {
    myContainer.current.innerHTML = "NIÐURTALNING";
  });
  const orderButton = useRef(() => {
    orderButton.current.innerHTML = "PANTA"
  });

  // Set the date we're counting down to
  var countDownDate = new Date("August 15, 2022 16:50:00").getTime();

  // Update the count down every 1 second
  var x = setInterval(function () {

    // Get today's date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Display the result in the element with id="demo"
    myContainer.current.innerHTML = days + "d " + hours + "k "
      + minutes + "m " + seconds + "s ";

    // If the count down is finished, write some text
    if (distance < 0) {
      clearInterval(x);
      myContainer.current.innerHTML = "LOKAÐ FYRIR PANTANIR";
      orderButton.current.innerHTML = "SKOÐA"
    }
  }, 1000);

  const product1Url = "https://x18tracker.rekstrarlausnir.com/api/v2/product/1";
  const { data: product1Data, error: product1Error } = useSwr(product1Url, { fetcher });
  var product1description = product1Data && !product1Error ? product1Data : defaultProduct;
  let { name: productName, imgurl: imageUrl, category, price, description, orderurl } = product1description.product[0];

  var changshaRouteStyle = {
    "color": "rgb(255,137,1)",
    "weight": 4,
    "opacity": 1,
    "saturation": 1
  };

  const tIcon = new Icon({
    iconUrl: truckIcon,
    iconSize: [14, 14],
    iconAnchor: [6, 6],
    popupAnchor: [0, -13],
  });

  const fIcon = new Icon({
    iconUrl: factoryIcon,
    iconSize: [22, 20],
    iconAnchor: [10, 15],
    popupAnchor: [0, -13],
  });

  const pIcon = new Icon({
    iconUrl: portIcon,
    iconSize: [22, 20],
    iconAnchor: [10, 15],
    popupAnchor: [0, -13],
  });

  const changShaLoc = [28.2231206, 112.9402632, 0];
  const shenshenLoc = [22.4736986, 114.0565446];
  const centerLoc = [24.9069742, 113.1022974];

  return (
    <MapContainer
      style={{ height: "100vh" }}
      center={centerLoc}
      zoom={7}
      scrollWheelZoom={true}
      maxZoom={9}
      minZoom={6}
      bounceAtZoomLimits={true}
      maxBoundsViscosity={0.75}
      zoomControl={true}
      maxBounds={[
        [-10, 70],
        [50, 160]
      ]} >
      <TileLayer noWrap={true} {...tilelayer} />
      <GeoJSON data={changsha} style={changshaRouteStyle} />

      <Marker
        position={truckLoc5}
        icon={tIcon}
        eventHandlers={{
          mouseover: (event) => event.target.openPopup(),
          // mouseout: (event) => event.target.closePopup()
        }
        }>

        <Popup>
          <img className="imagePopupFactory" src={`https://${imageUrl}`} alt="factory, people working" />
          <hr className="hrImage" />
          <p className="productname-text">{productName}</p>
          <p className="category-text">Flokkar: {category}</p>
          <p className="description-text">{description}.</p>
          <div className="countdownText">Vara í flutningi</div>
          <p className="price-text"><strong>Verð:</strong> {Number(price).toLocaleString('is')} kr.</p>
          <span className="btn-center">
            <button ref={orderButton} className="btn btn-reverse float-left submit-button" onClick={() => { window.location.href = orderurl }}>SKOÐA</button>
          </span>
        </Popup>
      </Marker>

      <Marker
        position={changShaLoc}
        icon={fIcon}
        eventHandlers={{
          mouseover: (event) => event.target.openPopup(),
          // mouseout: (event) => event.target.closePopup()
        }
        }>
        <Popup>
          <img className="imagePopupFactory" src={`https://${imageUrl}`} alt="factory, people working" />
          <hr className="hrImage" />
          <p className="productname-text">{productName}</p>
          <p className="category-text">Flokkar: {category}</p>
          <p className="description-text">{description}.</p>
          <div className="countdownText">Pöntun verður send eftir:</div>
          <div ref={myContainer} className="countdownTimer">Niðurtalning</div>
          <p className="price-text"><strong>Verð:</strong> {Number(price).toLocaleString('is')} kr.</p>
          <span className="btn-center">
            <button ref={orderButton} className="btn btn-reverse float-left submit-button" onClick={() => { window.location.href = orderurl }}>PANTA</button>
          </span>
        </Popup>
      </Marker>

      <Marker
        position={shenshenLoc}
        icon={pIcon}
        eventHandlers={{
          mouseover: (event) => event.target.openPopup(),
          mouseout: (event) => event.target.closePopup()
        }
        }>
        <Popup>
          <img className="imagePopupFactory" src="https://container-news.com/wp-content/uploads/2018/12/Port-of-Shenzhen-Port-of-Yantia_Sh2-768x512.jpg" alt="" srcSet="" />
          <p>Höfnin í Shenshen.</p>
        </Popup>
      </Marker>

    </MapContainer>
  )
}

export default TruckRoute