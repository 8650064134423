import { Icon } from 'leaflet';

import shipIcon from '../assets/shipIcon.png';
import factoryIcon from '../assets/factoryIcon.png';
import truckIcon from '../assets/truckIcon.png';
import portIcon from '../assets/portIcon.jpg'
import airIcon from '../assets/airIcon.png'


//hebei, changsha, foshan, fujian
  export const sIcon = new Icon({
    iconUrl: shipIcon,
    iconSize: [40, 22],
    iconAnchor: [25, 22],
    popupAnchor: [0, -13],
  });

  export const aIcon = new Icon({
    iconUrl: airIcon,
    iconSize: [50, 30],
    iconAnchor: [35, 22],
    popupAnchor: [0, -13],
  });

  export const fIcon = new Icon({
    iconUrl: factoryIcon,
    iconSize: [22, 20],
    iconAnchor: [10, 15],
    popupAnchor: [0, -13],
  });

  export const tIcon = new Icon({
    iconUrl: truckIcon,
    iconSize: [14, 14],
    iconAnchor: [6, 6],
    popupAnchor: [0, -13],
  });

  export const pIcon = new Icon({
    iconUrl: portIcon,
    iconSize: [22, 20],
    iconAnchor: [10, 15],
    popupAnchor: [0, -13],
  });

  export var seaRouteStyle = {
    "color": "#084AF3",
    "weight": 6,
    "opacity": 0.9,
    "saturation": 0.4
  };

  export var airRouteStyle = {
    "color": "#cf151b",
    "weight": 6,
    "opacity": 0.9,
    "saturation": 0.4
  };

  export var shanghaiRouteStyle = {
    "color": "green",
    "weight": 4,
    "opacity": 0.9,
    "saturation": 0.4
  };

  export var hebeiRouteStyle = {
    "color": "orange",
    "weight": 4,
    "opacity": 0.9,
    "saturation": 0.4
  };

  export var changshaRouteStyle = {
    "color": "black" ,
    "weight": 4,
    "opacity": 1,
    "saturation": 1
  };

  export var foshanRouteStyle = {
    "color": "teal",
    "weight": 4,
    "opacity": 0.9,
    "saturation": 0.4
  };

  export var fujianRouteStyle = {
    "color": "rgb(255,137,1)",
    "weight": 4,
    "opacity": 0.9,
    "saturation": 0.4
  };