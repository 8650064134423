import React, { useRef } from "react";

//Route from Shenshen to Reykjavik
import { seaPlot } from '../util/seaPlot';

//Icons
import portIcon from '../assets/portIcon.jpg'
import shipIcon from '../assets/shipIcon.png';

//Leaflet
import { MapContainer, Marker, TileLayer, GeoJSON, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';
import tilelayer from "../util/tilelayer.js";

//Fetching data
import useSwr from "swr";

const fetcher = (...args) => fetch(...args).then(response => response.json());



const Ship = () => {

// COUNTDOWN LOGIC START
  const myContainer = useRef(() => {
    myContainer.current.innerHTML = "NIÐURTALNING"
  });
  const orderButton = useRef(() => {
    orderButton.current.innerHTML = "PANTA"

  });
  // Set the date we're counting down to
  var countDownDate = new Date("August 30, 2022 14:05:00").getTime();

  // Update the count down every 1 second
  var x = setInterval(function () {

    // Get today's date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Display the result in the element with id="demo"
    myContainer.current.innerHTML = days + "d " + hours + "k "
      + minutes + "m " + seconds + "s ";

    // If the count down is finished, write some text
    if (distance < 0) {
      clearInterval(x);
      myContainer.current.innerHTML = "VARA Í TOLLI";
      orderButton.current.innerHTML = "SKOÐA"
    }
  }, 1000);

  //COUNTDOWN LOGIC END ----
  
// GETTING PRODUCT DATA 
  var defaultProduct = {
    "product": [
      {
        "id": 555,
        "name": "Product not ready",
        "category": "loading, not connected, internet problem, error in system",
        "price": 349000,
        "description": "This is a error product",
        "imgurl": "cdn.mattressonline.co.uk/content/products/full/sealy-millionaire-orthopaedic-mattress-full.jpg"
      }
    ]
  }

  const product1Url = "https://x18tracker.rekstrarlausnir.com/api/v2/product/1";
  const { data: product1Data, error: product1Error } = useSwr(product1Url, { fetcher });
  var product1description = product1Data && !product1Error ? product1Data : defaultProduct;
  let { name: productName, imgurl: imageUrl, category, price, description, orderurl } = product1description.product[0];

  //default positon logic
  const defaultArray = [39.5127203, 150.9807516];

  var ship1Url = "https://x18tracker.rekstrarlausnir.com/api/v2/sea/ship1";
  const { data: shipData, error: shipError } = useSwr(ship1Url, { fetcher });
  const location = shipData && !shipError ? shipData : defaultArray;
  //lat Lon switch
  const lat = shipData && !shipError ? location.Point[0] : defaultArray[0];
  const lon = shipData && !shipError ? location.Point[1] : defaultArray[1];
  var shipLoc = [lon, lat];

  var shipRouteStyle = {
    "color": "rgb(255,137,1)",
    "weight": 4,
    "opacity": 1,
    "saturation": 1
  };

  const sIcon = new Icon({
    iconUrl: shipIcon,
    iconSize: [40, 22],
    iconAnchor: [25, 22],
    popupAnchor: [0, -13],
  });

  const pIcon = new Icon({
    iconUrl: portIcon,
    iconSize: [22, 20],
    iconAnchor: [10, 15],
    popupAnchor: [0, -13],
  });

  const shenshenLoc = [22.4736986, 114.0565446];
  const centerLoc = [32.3322021, 30.5200207]
  return (
    <MapContainer
      style={{ height: "100vh" }}
      center={centerLoc}
      zoom={3}
      scrollWheelZoom={true}
      maxZoom={7}
      minZoom={2}
      bounceAtZoomLimits={true}
      maxBoundsViscosity={0.75}
      zoomControl={true}
      maxBounds={[
        [-180, -180],
        [180, 180]
      ]} >
      <TileLayer noWrap={true} {...tilelayer} />
      <GeoJSON data={seaPlot} style={shipRouteStyle} />

      <Marker
        position={shipLoc}
        icon={sIcon}
        eventHandlers={{
          mouseover: (event) => event.target.openPopup(),
          //mouseout: (event) => event.target.closePopup()
        }
        }>
        <Popup>
          <img className="imagePopupShip" src={`https://${imageUrl}`} alt="factory, people working" />
          <hr className="hrImage" />
          <p className="productname-text">{productName}</p>
          <p className="category-text">Flokkar: {category}</p>
          <p className="description-text">{description}.</p>
          <div className="countdownText">Áætlaður komutími:</div>
          <div ref={myContainer} className="countdownTimer">Niðurtalning</div>
          <p className="price-text"><strong>Verð:</strong> {Number(price).toLocaleString('is')} kr.</p>
          <span className="btn-center">
            <button ref={orderButton} className="btn btn-reverse float-left submit-button" onClick={() => { window.location.href = orderurl }}>SKOÐA</button>
          </span>

        </Popup>
      </Marker>

      <Marker
        position={shenshenLoc}
        icon={pIcon}
        eventHandlers={{
          mouseover: (event) => event.target.openPopup(),
          mouseout: (event) => event.target.closePopup()
        }
        }>
        <Popup>
          <img className="imagePopupFactory" src="https://container-news.com/wp-content/uploads/2018/12/Port-of-Shenzhen-Port-of-Yantia_Sh2-768x512.jpg" alt="" srcSet="" />
          <p>Höfnin í Shenshen.</p>
        </Popup>
      </Marker>

    </MapContainer>
  )
}

export default Ship