import React, { useRef } from 'react'

//Icon
import truckIcon from '../assets/truckIcon.png';
import factoryIcon from '../assets/factoryIcon.png';
import portIcon from '../assets/portIcon.jpg'

//Leaflet
import { MapContainer, Marker, TileLayer, GeoJSON, Popup } from 'react-leaflet';
import { hebei } from '../util/landPlot';
import { Icon } from 'leaflet';
import tilelayer from "../util/tilelayer.js";

//Fetching data
import useSwr from "swr";

const fetcher = (...args) => fetch(...args).then(response => response.json());

const TruckRoute = () => {
  //default positon logic
  const defaultArray = [39.5127203, 150.9807516];

  var defaultProduct = {
    "product": [
      {
        "id": 555,
        "name": "Product not ready",
        "category": "loading, not connected, internet problem, error in system",
        "price": 349000,
        "description": "This is a error product",
        "imgurl": "cdn.mattressonline.co.uk/content/products/full/sealy-millionaire-orthopaedic-mattress-full.jpg"
      }
    ]
  }

  const myContainer = useRef(() => {
    myContainer.current.innerHTML = "NIÐURTALNING";
  });
  const orderButton = useRef(() => {
    orderButton.current.innerHTML = "PANTA"
  });

  // Set the date we're counting down to
  var countDownDate = new Date("December 2, 2022 08:00:00").getTime();

  // Update the count down every 1 second
  var x = setInterval(function () {

    // Get today's date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Display the result in the element with id="demo"
    myContainer.current.innerHTML = days + "d " + hours + "k "
      + minutes + "m " + seconds + "s ";

    // If the count down is finished, write some text
    if (distance < 0) {
      clearInterval(x);
      myContainer.current.innerHTML = "LOKAÐ FYRIR PANTANIR";
      orderButton.current.innerHTML = "SKOÐA"
    }
  }, 1000);

  //Product fetching setup
  const product1Url = "https://x18tracker.rekstrarlausnir.com/api/v2/product/1";
  const { data: product1Data, error: product1Error } = useSwr(product1Url, { fetcher });
  var product1description = product1Data && !product1Error ? product1Data : defaultProduct;
  let { name: productName, imgurl: imageUrl, category, price, description, orderurl } = product1description.product[0];


  var truckUrl2 = 'https://x18tracker.rekstrarlausnir.com/api/v2/land/factory2';

  const { data: truckData2, error: truckError2 } = useSwr(truckUrl2, { fetcher });
  // const truckLocation2 = truckData2 && !truckError2 ? truckData2 : defaultArray;
  // const truckLat2 = truckData2 && !truckError2 ? truckLocation2.Point[0] : defaultArray[0];
  // const truckLon2 = truckData2 && !truckError2 ? truckLocation2.Point[1] : defaultArray[1]
  // var truckLoc2 = [truckLon2, truckLat2];

  function checkForError(validatedData, validatedError) {
    var validateLocation = [39.5127203, 150.9807516];
    if (validatedData === undefined) {
      validateLocation = [39.5127203, 150.9807516];
      console.log("THERE IS ERROR!")
    }
    else {
      try {
        validateLocation = [validatedData.Point[1], validatedData.Point[0]];
      } catch (err) {
        console.log("ERROR WAS CATCHED: " + err);
        validateLocation = [39.5127203, 150.9807516];
        return validateLocation;
      }
    }
    return validateLocation;
  }

  var truckLoc2 = checkForError(truckData2, truckError2);

  var changshaRouteStyle = {
    "color": "rgb(255,137,1)",
    "weight": 4,
    "opacity": 1,
    "saturation": 1
  };

  const tIcon = new Icon({
    iconUrl: truckIcon,
    iconSize: [14, 14],
    iconAnchor: [6, 6],
    popupAnchor: [0, -13],
  });

  const fIcon = new Icon({
    iconUrl: factoryIcon,
    iconSize: [22, 20],
    iconAnchor: [10, 15],
    popupAnchor: [0, -13],
  });

  const pIcon = new Icon({
    iconUrl: portIcon,
    iconSize: [22, 20],
    iconAnchor: [10, 15],
    popupAnchor: [0, -13],
  });

  const hebeiLoc = [38.6189978, 114.6846821, 0];
  const shenshenLoc = [22.4736986, 114.0565446];
  const centerLoc = [30.9069742, 112.1022974];

  return (
    <MapContainer
      style={{ height: "100vh" }}
      center={centerLoc}
      zoom={6}
      scrollWheelZoom={true}
      maxZoom={9}
      minZoom={6}
      bounceAtZoomLimits={true}
      maxBoundsViscosity={0.75}
      zoomControl={true}
      maxBounds={[
        [-10, 70],
        [50, 160]
      ]} >
      <TileLayer noWrap={true} {...tilelayer} />
      <GeoJSON data={hebei} style={changshaRouteStyle} />

      <Marker
        position={truckLoc2}
        icon={tIcon}
        eventHandlers={{
          mouseover: (event) => event.target.openPopup(),
          //mouseout: (event) => event.target.closePopup()
        }
        }>
        <Popup>
          <img className="imagePopupFactory" src={`https://${imageUrl}`} alt="factory, people working" />
          <hr className="hrImage" />
          <p className="productname-text">{productName}</p>
          <p className="category-text">Flokkar: {category}</p>
          <p className="description-text">{description}.</p>
          <div className="countdownText">Vara er á leiðinni til Hong Kong!</div>
          <br />
          <span className="btn-center">
            <button ref={orderButton} className="btn btn-reverse float-left submit-button" onClick={() => { window.open(orderurl, "_blank") }}>SKOÐA</button>
          </span>
        </Popup>
      </Marker>

      <Marker
        position={hebeiLoc}
        icon={fIcon}
        eventHandlers={{
          mouseover: (event) => event.target.openPopup(),
          //mouseout: (event) => event.target.closePopup()
        }
        }>
        <Popup>
          <img className="imagePopupFactory" src={`https://${imageUrl}`} alt="factory, people working" />
          <hr className="hrImage" />
          <p className="productname-text">{productName}</p>
          <p className="category-text">Flokkar: {category}</p>
          <p className="description-text">{description}.</p>
          <br />
          <p><h4>Opið fyrir pantanir</h4></p>
          <div className="countdownText">Vara fer í framleiðslu eftir:</div>
          <div ref={myContainer} className="countdownTimer">Niðurtalning</div>
          <p className="price-text"><strong>Verð:</strong> {Number(price).toLocaleString('is')} kr.</p>
          <span className="btn-center">
            <button ref={orderButton} className="btn btn-reverse float-left submit-button" onClick={() => { window.open(orderurl, "_blank") }}>Panta</button>
          </span>
        </Popup>
      </Marker>

      <Marker
        position={shenshenLoc}
        icon={pIcon}
        eventHandlers={{
          mouseover: (event) => event.target.openPopup(),
          mouseout: (event) => event.target.closePopup()
        }
        }>
        <Popup>
          <img className="imagePopupFactory" src="https://container-news.com/wp-content/uploads/2018/12/Port-of-Shenzhen-Port-of-Yantia_Sh2-768x512.jpg" alt="" srcSet="" />
          <p>Höfnin í Shenshen.</p>
        </Popup>
      </Marker>

    </MapContainer>
  )
}

export default TruckRoute