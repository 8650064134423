import '../App.css';
import React, { useRef } from 'react';

//Leaflet
import { shanghai, hebei, changsha, foshan, fujian } from '../util/landPlot';
import { sIcon, aIcon, tIcon, pIcon, fIcon, seaRouteStyle, airRouteStyle, shanghaiRouteStyle, hebeiRouteStyle, changshaRouteStyle, foshanRouteStyle, fujianRouteStyle  } from '../util/routeStyles';
import { seaPlot } from '../util/seaPlot';
import { airPlot } from '../util/airPlot';
import { MapContainer, Marker, TileLayer, GeoJSON, Popup } from 'react-leaflet';

//TileLayer Import
import tilelayer from "../util/tilelayer.js";

//Fetching data
import useSwr from "swr";

const fetcher = (...args) =>  fetch(...args).then(response => response.json());
//const fetcher = (...args) => axios.get(...args).then(res => res.data);


const Map = () => {
  // const prefetchedData = [39.5127203, 150.9807516];
  const options = { refreshInterval: 30000 }
  //Fetching data
  //var ship1Url = "https://x18tracker.rekstrarlausnir.com/api/v2/sea/ship1";
  var ship1Url = "https://x18tracker.rekstrarlausnir.com/api/v2/sea/ship1";
  var shipUrl2 = "https://x18tracker.rekstrarlausnir.com/api/v2/sea/ship2";

  //Air datat
  var airUrl1 = "https://x18tracker.rekstrarlausnir.com/api/v2/air/air1";
  //Truck Location Data
  var truckUrl1 = 'https://x18tracker.rekstrarlausnir.com/api/v2/land/factory1'
  var truckUrl2 = 'https://x18tracker.rekstrarlausnir.com/api/v2/land/factory2';
  var truckUrl3 = 'https://x18tracker.rekstrarlausnir.com/api/v2/land/factory3';
  var truckUrl4 = 'https://x18tracker.rekstrarlausnir.com/api/v2/land/factory4';
  var truckUrl5 = 'https://x18tracker.rekstrarlausnir.com/api/v2/land/factory5';
  const { data: airData1, error: airError1 } = useSwr(airUrl1, fetcher , options);
  const { data: shipData1, error: shipError1 } = useSwr(ship1Url,  fetcher , options);
  const { data: shipData2, error: shipError2 } = useSwr(shipUrl2,  fetcher , options);
  const { data: truckData1, error: truckError1 } = useSwr(truckUrl1,  fetcher , options);
  const { data: truckData2, error: truckError2 } = useSwr(truckUrl2,  fetcher , options);
  const { data: truckData3, error: truckError3 } = useSwr(truckUrl3,  fetcher , options);
  const { data: truckData4, error: truckError4 } = useSwr(truckUrl4,  fetcher , options);
  const { data: truckData5, error: truckError5 } = useSwr(truckUrl5,  fetcher , options);

  // Product Data
  //Data on the Truck
  var defaultProduct = {
    "product": [
      {
        "id": 555,
        "name": "Product not ready",
        "category": "loading, not connected, internet problem, error in system",
        "description": "This is a description of a error product",
        "price": 349000,
        "imgurl": "cdn.mattressonline.co.uk/content/products/full/sealy-millionaire-orthopaedic-mattress-full.jpg",
        "orderurl": ""
      }
    ]
  }

  var defaultProduct2 = {
    "product": [
      {
        "id": 555,
        "name2": "Product not ready",
        "category2": "loading, not connected, internet problem, error in system",
        "description2": "This is a description of a error product",
        "price2": 349000,
        "img2url": "cdn.mattressonline.co.uk/content/products/full/sealy-millionaire-orthopaedic-mattress-full.jpg",
        "orderurl2": "https://www.x18.is"
      }
    ]
  }

  //Reference
  const myContainer = useRef(() => {
    myContainer.current.innerHTML = "NIÐURTALNING";
  });
  const myContainer2 = useRef(() => {
    myContainer2.current.innerHTML = "NIÐURTALNING";
  });

  const orderButton = useRef(() => {
    orderButton.current.innerHTML = "PANTA"
  });

  // Set the date we're counting down to
  var countDownDate = new Date("December 2, 2022 08:00:00").getTime();
  var countDownDate2 = new Date("November 4, 2022 08:00:00").getTime();

  //Creating a function, that only has different starting date, and reference to use
  function countDown(start, containerRef) {
    setInterval(() => {
      var now = new Date().getTime();
      var distance = start - now;

      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Display the result in the element with reference myContainer
    // Update the count down every 1 second

      if (containerRef.current) {
        containerRef.current.innerHTML = days + "d " + hours + "k "
          + minutes + "m " + seconds + "s ";
      }

      if (distance < 0) {
        clearInterval(countDown);
        containerRef.current.innerHTML = "LOKAÐ FYRIR PANTANIR";
        orderButton.current.innerHTML = "SKOÐA"
      }
    }, 1000)
  }

  countDown(countDownDate, myContainer)
  countDown(countDownDate2, myContainer2)

  //PRODUCT 1
  const product1Url = "https://x18tracker.rekstrarlausnir.com/api/v2/product/1";
  const { data: product1Data, error: product1Error } = useSwr(product1Url, { fetcher });
  var product1description = product1Data && !product1Error ? product1Data : defaultProduct;
  let { name: productName, imgurl: imageUrl, category, price, description, orderurl } = product1description.product[0];

  //PRODUCT 2
  const productUrl2 = "https://x18tracker.rekstrarlausnir.com/api/v2/product/2";
  const { data: productData2, error: productError2 } = useSwr(productUrl2, { fetcher });
  var productdescription2 = productData2 && !productError2 ? productData2 : defaultProduct2;
  let { name: productName2, imgurl: imageUrl2, category: category2, price: price2, description: description2, orderurl: orderurl2 } = productdescription2.product[0];


//CHECKING FOR ERRORS, switching LON And LAT
  function checkForError(validatedData, validatedError ){
    var validateLocation = [39.5127203, 150.9807516]; 
    if (validatedData === undefined) {
      validateLocation = [39.5127203, 150.9807516];
      console.log("THERE IS  ERROR!")
    }
    else {
      try {
        validateLocation = [validatedData.Point[1], validatedData.Point[0]];
      } catch (err) {
        console.log("ERROR WAS CATCHED: " + err);
        validateLocation = [39.5127203, 150.9807516];
        return validateLocation;
      }
    }
    return validateLocation;
  }

  //Location for each object
  var airLocation1 = checkForError(airData1, airError1);
  var shipLocation1 = checkForError(shipData1, shipError1);
  var shipLocation2 = checkForError(shipData2, shipError2);
  var truckLocation1 = checkForError(truckData1, truckError1);
  var truckLocation2 = checkForError(truckData2, truckError2);
  var truckLocation3 = checkForError(truckData3, truckError3);
  var truckLocation4 = checkForError(truckData4, truckError4);
  var truckLocation5 = checkForError(truckData5, truckError5);

  //Locations of current factories
  const shanghaiLoc = [31.2286536, 121.4305013];
  const hebeiLoc = [38.6189978, 114.6846821, 0];
  const fujianLoc = [26.078591, 119.2864896, 0];
  const foshanLoc = [23.0215318, 113.1269237, 0];
  const changShaLoc = [28.2231206, 112.9402632, 0];
  const shenshenLoc = [22.4736986, 114.0565446];
  const truckDemoLoc = [26.6167941, 117.9511312];
  const reykjavikLoc = [64.1948976, -22.0972898]

  return (
    <div>
    <MapContainer
      className='test'
      style={{ height: "100vh" }}
      center={truckDemoLoc}
      zoom={3}
      scrollWheelZoom={true}
      maxZoom={12}
      minZoom={3}
      bounceAtZoomLimits={false}
      maxBoundsViscosity={0.75}
      zoomControl={true}
      maxBounds={[
        [-180, -180],
        [180, 180]
      ]}>
      <TileLayer noWrap={true} {...tilelayer} />
{/* truck */}
      <GeoJSON data={shanghai} style={shanghaiRouteStyle} />
      <GeoJSON data={hebei} style={hebeiRouteStyle} />
      <GeoJSON data={changsha} style={changshaRouteStyle} />
      <GeoJSON data={foshan} style={foshanRouteStyle} />
      <GeoJSON data={fujian} style={fujianRouteStyle} />
{/* air */}
      <GeoJSON data={airPlot} style={airRouteStyle} />
{/* sea */}
      <GeoJSON data={seaPlot} style={seaRouteStyle} />

      {/* Flugvélarnar */}
      {/* Flugvél 1 */}
      <Marker
        position={airLocation1}
        icon={aIcon}
        eventHandlers={{
          mouseover: (event) => event.target.openPopup(),
          //mouseout: (event) => event.target.closePopup()
        }
        }>
        <Popup>
          <img className="imagePopupFactory" src={`${imageUrl2}`} alt="lego alternative" />
          <hr className="hrImage" />
          <p className="productname-text">{productName2}</p>
          <p className="category-text">Flokkar: {category2}</p>
          <p className="description-text">{description2}.</p>
          <br />
          <div className="countdownText">Vara er á leiðinni</div>
          <p className="price-text"><strong>Verð:</strong> {Number(price2).toLocaleString('is')} kr.</p>
          <span className="btn-center">
            <button className="btn btn-reverse float-left submit-button" onClick={() => {
              window.open( orderurl2 || "https://www.x18.is", "_blank")
            }}>SKOÐA
            </button>
          </span>
        </Popup>
      </Marker>

      {/* Skipin */}
      <Marker
        position={shipLocation1}
        icon={sIcon}
        eventHandlers={{
          mouseover: (event) => event.target.openPopup(),
          //mouseout: (event) => event.target.closePopup()
        }
        }>
        <Popup>
          <img className="imagePopupFactory" src={`${imageUrl2}`} alt="lego alternative" />
          <hr className="hrImage" />
          <p className="productname-text">{productName2}</p>
          <p className="category-text">Flokkar: {category2}</p>
          <p className="description-text">{description2}.</p>
          <br />
          <div className="countdownText">Vara er á leiðinni</div>
          <p className="price-text"><strong>Verð:</strong> {Number(price2).toLocaleString('is')} kr.</p>
          <span className="btn-center">
            <button className="btn btn-reverse float-left submit-button" onClick={() => {
              window.open(orderurl, "_blank")
            }}>SKOÐA
            </button>
          </span>
        </Popup>
      </Marker>
      {/* Ship 2 */}
      <Marker
        position={shipLocation2}
        icon={sIcon}
        eventHandlers={{
          mouseover: (event) => event.target.openPopup(),
          //mouseout: (event) => event.target.closePopup()
        }
        }>
        <Popup>
          <img className="imagePopupFactory" src={`https://${imageUrl}`} alt="mattress" />
          <hr className="hrImage" />
          <p className="productname-text">{productName}</p>
          <p className="category-text">Flokkar: {category}</p>
          <p className="description-text">{description}.</p>
          <br />
          <div className="countdownText">Vara er á leiðinni</div>
          <p className="price-text"><strong>Verð:</strong> {Number(price).toLocaleString('is')} kr.</p>
          <span className="btn-center">
            <button className="btn btn-reverse float-left submit-button" onClick={() => {
              window.open(orderurl, "_blank")
            }}>SKOÐA
            </button>
          </span>
        </Popup>
      </Marker>

      {/* Trukkarnir */}
      <Marker
        position={truckLocation1}
        icon={tIcon}
        eventHandlers={{
          mouseover: (event) => event.target.openPopup(),
      //mouseout: (event) => event.target.closePopup()
        }
        }>
        <Popup>
          <img className="imagePopupFactory" src={`https://${imageUrl}`} alt="factory, people working" />
          <hr className="hrImage" />
          <p className="productname-text">{productName}</p>
          <p className="category-text">Flokkar: {category}</p>
          <p className="description-text">{description}.</p>
          <br />
          <div className="countdownText">Vara er á leiðinni</div>
          <p className="price-text"><strong>Verð:</strong> {Number(price).toLocaleString('is')} kr.</p>
          <span className="btn-center">
            <button className="btn btn-reverse float-left submit-button" onClick={() => {
              window.open(orderurl, "_blank")
            }}>SKOÐA
            </button>
          </span>
        </Popup>
      </Marker>

      <Marker
        position={truckLocation2}
        icon={tIcon}
        eventHandlers={{
          mouseover: (event) => event.target.openPopup(),
      //mouseout: (event) => event.target.closePopup()
        }
        }>
        <Popup>
          <img className="imagePopupFactory" src={`https://${imageUrl}`} alt="factory, people working" />
          <hr className="hrImage" />
          <p className="productname-text">{productName}</p>
          <p className="category-text">Flokkar: {category}</p>
          <p className="description-text">{description}.</p>
          <br />
          <div className="countdownText">Vara er á leiðinni</div>
          <p className="price-text"><strong>Verð:</strong> {Number(price).toLocaleString('is')} kr.</p>
          <span className="btn-center">
            <button className="btn btn-reverse float-left submit-button" onClick={() => {
              window.open(orderurl, "_blank")
            }}>SKOÐA
            </button>
          </span>
        </Popup>
      </Marker>

      <Marker
        position={truckLocation3}
        icon={tIcon}
        eventHandlers={{
          mouseover: (event) => event.target.openPopup(),
      //mouseout: (event) => event.target.closePopup()
        }
        }>
        <Popup>
          <img className="imagePopupFactory" src={`https://${imageUrl}`} alt="factory, people working" />
          <hr className="hrImage" />
          <p className="productname-text">{productName}</p>
          <p className="category-text">Flokkar: {category}</p>
          <p className="description-text">{description}.</p>
          <br />
          <div className="countdownText">Vara er á leiðinni</div>
          <p className="price-text"><strong>Verð:</strong> {Number(price).toLocaleString('is')} kr.</p>
          <span className="btn-center">
            <button className="btn btn-reverse float-left submit-button" onClick={() => {
              window.open(orderurl, "_blank")
            }}>SKOÐA
            </button>
          </span>
        </Popup>
      </Marker>

      <Marker
        position={truckLocation4}
        icon={tIcon}
        eventHandlers={{
          mouseover: (event) => { event.target.openPopup() },
          //mouseout: (event) => event.target.closePopup()
        }
        }>
        <Popup>
          <img className="imagePopupFactory" src={`https://${imageUrl}`} alt="factory, people working" />
          <hr className="hrImage" />
          <p className="productname-text">{productName}</p>
          <p className="category-text">Flokkar: {category}</p>
          <p className="description-text">{description}.</p>
          <br />
          <div className="countdownText">Vara er á leiðinni</div>
          <p className="price-text"><strong>Verð:</strong> {Number(price).toLocaleString('is')} kr.</p>
          <span className="btn-center">
            <button className="btn btn-reverse float-left submit-button" onClick={() => {
              window.open(orderurl, "_blank")
            }}>SKOÐA
            </button>
          </span>
        </Popup>
      </Marker>

      <Marker
        position={truckLocation5}
        icon={tIcon}
        eventHandlers={{
          mouseover: (event) => {
            event.target.openPopup()
          },
          //mouseout: (event) => event.target.closePopup()
        }
        }>
        <Popup>
          <img className="imagePopupFactory" src={`https://${imageUrl}`} alt="factory, people working" />
          <hr className="hrImage" />
          <p className="productname-text">{productName}</p>
          <p className="category-text">Flokkar: {category}</p>
          <p className="description-text">{description}.</p>
          <br />
          <div className="countdownText">Vara er á leiðinni</div>
          <p className="price-text"><strong>Verð:</strong> {Number(price).toLocaleString('is')} kr.</p>
          <span className="btn-center">
            <button className="btn btn-reverse float-left submit-button" onClick={() => {
              window.open(orderurl, "_blank")
            }}>SKOÐA
            </button>
          </span>
        </Popup>
      </Marker>

      {/* Verksmiðjurnar */}
      <Marker
        position={shanghaiLoc}
        icon={fIcon}
        eventHandlers={{
          mouseover: (event) => event.target.openPopup(),
          //mouseout: (event) => event.target.closePopup()
        }
        }>
        <Popup>
          <img className="imagePopupFactory" src={`https://${imageUrl}`} alt="factory, people working" />
          <hr className="hrImage" />
          <p className="productname-text">{productName}</p>
          <p className="category-text">Flokkar: {category}</p>
          <p className="description-text">{description}.</p>
          <p>THIS IS A TEST</p>
          <div className="countdownText">Lokað á pantanir eftir:</div>
          <div ref={myContainer2} className="countdownTimer">Niðurtalning</div>
          <p className="price-text"><strong>Verð:</strong> {Number(price).toLocaleString('is')} kr.</p>
          <span className="btn-center">
            <button ref={orderButton} className="btn btn-reverse float-left submit-button" onClick={() => { window.open(orderurl, "_blank") }}>Panta</button>
          </span>
        </Popup>
      </Marker>

      <Marker
        position={hebeiLoc}
        icon={fIcon}
        eventHandlers={{
          mouseover: (event) => event.target.openPopup(),
          //mouseout: (event) => event.target.closePopup()
        }
        }>
        <Popup>
          <img className="imagePopupFactory" src={`https://${imageUrl}`} alt="factory, people working" />
          <hr className="hrImage" />
          <p className="productname-text">{productName}</p>
          <p className="category-text">Flokkar: {category}</p>
          <p className="description-text">{description}.</p>
          <div className="countdownText">Lokað á pantanir eftir:</div>
          <div ref={myContainer} className="countdownTimer">Niðurtalning</div>
          <p className="price-text"><strong>Verð:</strong> {Number(price).toLocaleString('is')} kr.</p>
          <span className="btn-center">
            <button ref={orderButton} className="btn btn-reverse float-left submit-button" onClick={() => { window.open(orderurl, "_blank") }}>Panta</button>
          </span>
        </Popup>
      </Marker>

      <Marker
        position={fujianLoc}
        icon={fIcon}
        eventHandlers={{
          mouseover: (event) => event.target.openPopup(),
          //mouseout: (event) => event.target.closePopup()
        }
        }>
        <Popup>
          <img className="imagePopupFactory" src={`https://${imageUrl}`} alt="factory, people working" />
          <hr className="hrImage" />
          <p className="productname-text">{productName}</p>
          <p className="category-text">Flokkar: {category}</p>
          <p className="description-text">{description}.</p>
          <div className="countdownText">Lokað á pantanir eftir:</div>
          <div ref={myContainer} className="countdownTimer">Niðurtalning</div>
          <p className="price-text"><strong>Verð:</strong> {Number(price).toLocaleString('is')} kr.</p>
          <span className="btn-center">
            <button ref={orderButton} className="btn btn-reverse float-left submit-button" onClick={() => { window.open(orderurl, "_blank") }}>Panta</button>
          </span>
        </Popup>
      </Marker>

      <Marker
        position={foshanLoc}
        icon={fIcon}
        eventHandlers={{
          mouseover: (event) => event.target.openPopup(),
          //mouseout: (event) => event.target.closePopup()
        }
        }>
        <Popup>
          <img className="imagePopupFactory" src={`https://${imageUrl}`} alt="factory, people working" />
          <hr className="hrImage" />
          <p className="productname-text">{productName}</p>
          <p className="category-text">Flokkar: {category}</p>
          <p className="description-text">{description}.</p>
          <div className="countdownText">Lokað á pantanir eftir:</div>
          <div ref={myContainer} className="countdownTimer">Niðurtalning</div>
          <p className="price-text"><strong>Verð:</strong> {Number(price).toLocaleString('is')} kr.</p>
          <span className="btn-center">
            <button ref={orderButton} className="btn btn-reverse float-left submit-button" onClick={() => { window.open(orderurl, "_blank") }}>Panta</button>
          </span>
        </Popup>
      </Marker>

      <Marker
        position={changShaLoc}
        icon={fIcon}
        eventHandlers={{
          mouseover: (event) => event.target.openPopup(),
          //mouseout: (event) => event.target.closePopup()
        }
        }>
        <Popup>
          <img className="imagePopupFactory" src={`https://${imageUrl}`} alt="factory, people working" />
          <hr className="hrImage" />
          <p className="productname-text">{productName}</p>
          <p className="category-text">Flokkar: {category}</p>
          <p className="description-text">{description}.</p>
          <div className="countdownText">Lokað á pantanir eftir:</div>
          <div ref={myContainer} className="countdownTimer">Niðurtalning</div>
          <p className="price-text"><strong>Verð:</strong> {Number(price).toLocaleString('is')} kr.</p>
          <span className="btn-center">
            <button ref={orderButton} className="btn btn-reverse float-left submit-button" onClick={() => { window.open(orderurl, "_blank") }}>Panta</button>
          </span>
        </Popup>
      </Marker>
      <Marker
        position={shenshenLoc}
        icon={pIcon}
        eventHandlers={{
          mouseover: (event) => event.target.openPopup(),
          mouseout: (event) => event.target.closePopup()
        }
        }>
        <Popup>
          <img className="imagePopupFactory" src="https://container-news.com/wp-content/uploads/2018/12/Port-of-Shenzhen-Port-of-Yantia_Sh2-768x512.jpg" alt="" srcSet="" />
          <p>Höfnin í Shenshen.</p>
        </Popup>
      </Marker>
      <Marker
        position={reykjavikLoc}
        icon={pIcon}
        eventHandlers={{
          mouseover: (event) => event.target.openPopup(),
          mouseout: (event) => event.target.closePopup()
        }
        }>
        <Popup>
            <img className="imagePopupFactory" src="https://visitreykjavik.is/sites/default/files/styles/original_medium/public/images/2020-09/old_harbour_0.png?itok=OrFZ_V6u" alt="" srcSet="" />
          <p>Höfnin í Reykjavík.</p>
        </Popup>
      </Marker>
    </MapContainer>
    </div>
  );
}

export default Map;
