// import Home from "./pages/Home";

// const App = () => {
//   return <Home />;
// };

// export default App;

import Home from "./pages/Home";

//Trucks
import Truck1 from "./pages/ShanghaiT";
import Truck2 from "./pages/HebeiT";
import Truck3 from "./pages/FuzhoT";
import Truck4 from "./pages/FoshanT";
import Truck5 from "./pages/ChangShaT";

//Factories
import Factory1 from "./pages/ShanghaiF";
import Factory2 from "./pages/HebeiF";
import Factory3 from "./pages/FoshanF";
import Factory4 from "./pages/FuzhoF";
import Factory5 from "./pages/ChangshaF";
import Test from "./pages/Test";


//Ships
import Ship1 from "./pages/Ship";

import React, { Component } from 'react';
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';

//Dashboard
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./components/login.component";
import Register from "./components/register.component";
import Dashboard from "./components/home.component";
import Profile from "./components/profile.component";
import BoardUser from "./components/board-user.component";
import BoardModerator from "./components/board-moderator.component";
import BoardAdmin from "./components/board-admin.component";
import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";
import { history } from './helpers/history';
import "./App.css";
import { connect } from "react-redux";


class App extends Component { 
constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);
    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
    history.listen((location) => {
      props.dispatch(clearMessage()); // clear message when changing location
    });
  }
  componentDidMount() {
    const user = this.props.user;
    if (user) {
      this.setState({
        currentUser: user,
        showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
    }
  }
  logOut() {
    this.props.dispatch(logout());
  }

  render() {
    const { currentUser, showModeratorBoard, showAdminBoard } = this.state;

    return (

      


      <Router history={history}>
        
        <Routes>

          <Route exact path='/dashboard' element={< Dashboard />}></Route>
           {/* <Route exact path="/login" element={< Login />}></Route> */}
          <Route exact path="/register" element={< Register />}></Route>
          <Route exact path="/profile" element={< Profile />}></Route>
          <Route path="/user" element={< BoardUser />}></Route>
          <Route path="/mod" element={< BoardModerator />}></Route>
          <Route path="/admin" element={< BoardAdmin />}></Route>      


          <Route exact path='/' element={< Home />}></Route>
          <Route exact path='/shanghai' element={< Truck1 />}></Route>
          <Route exact path='/hebei' element={< Truck2 />}></Route>          
          <Route exact path='/fuzho' element={< Truck3 />}></Route>          
          <Route exact path='/foshan' element={< Truck4 />}></Route>   
          <Route exact path='/changshan' element={< Truck5 />}></Route>                 
          <Route exact path='/factory1' element={< Factory1 />}></Route>
          <Route exact path='/factory2' element={< Factory2 />}></Route>
          <Route exact path='/factory3' element={< Factory3 />}></Route>
          <Route exact path='/factory4' element={< Factory4 />}></Route>
          <Route exact path='/factory5' element={< Factory5 />}></Route>         
          <Route exact path='/ship1' element={< Ship1 />}></Route>
          <Route exact path='/test' element={< Test />}></Route>         
        </Routes>
      </Router>
    )}

}
// function mapStateToProps(state) {
//   const { user } = state.auth;
//   return {
//     user,
//   };
// }
// export default connect(mapStateToProps)(App);


export default App;