import React, { useRef } from "react";
//Icon
import factoryIcon from '../assets/factoryIcon.png';
import portIcon from '../assets/portIcon.jpg'

//Leaflet
import { MapContainer, Marker, TileLayer, Popup } from 'react-leaflet';
import useSwr from "swr";
import { Icon } from 'leaflet';
import tilelayer from "../util/tilelayer.js";


const fetcher = (...args) => fetch(...args).then(response => response.json());



export default function Test() {
  var defaultProduct = {
    "product": [
      {
        "id": 555,
        "name": "Product not ready",
        "category": "loading, not connected, internet problem, error in system",
        "price": 349000,
        "description": "This is a error product",
        "imgurl": "cdn.mattressonline.co.uk/content/products/full/sealy-millionaire-orthopaedic-mattress-full.jpg"
      }
    ]
  }

  


  // Set the date we're counting down to
  var countDownDate = new Date("August 22, 2022 16:50:00").getTime();

  // Update the count down every 1 second
  var x = setInterval(function () {

    // Get today's date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Display the result in the element with id="demo"
    myContainer.current.innerHTML = days + "d " + hours + "k "
      + minutes + "m " + seconds + "s ";

    // If the count down is finished, write some text
    if (distance < 0) {
      clearInterval(x);
      myContainer.current.innerHTML = "LOKAÐ FYRIR PANTANIR";
      orderButton.current.innerHTML = "SKOÐA"
    }
  }, 1000);


  const myContainer = useRef(() => {
    
  });
  const orderButton = useRef(() => {
    orderButton.current.innerHTML = "PANTA"

  });

  // useEffect(() => {
  //   console.log("myContainer..", myContainer.current);
  // });

  const product1Url = "https://x18tracker.rekstrarlausnir.com/api/v2/product/1";
  const { data: product1Data, error: product1Error } = useSwr(product1Url, { fetcher });
  var product1description = product1Data && !product1Error ? product1Data : defaultProduct;
  let { name: productName, imgurl: imageUrl, category, price, description, orderurl } = product1description.product[0];

  // const product1Url = "http://localhost:5000/api/v2/product/1";
  // const { data: product1Data, error: product1Error } = useSwr(product1Url, { fetcher });

  // var product1description = product1Data && !product1Error ? product1Data : defaultText;
  // //console.log(product1description.product[0]);

  // let { name: productName, imgurl: imageUrl, category } = product1description.product[0];
  //console.log(imageUrl);
  const fIcon = new Icon({
    iconUrl: factoryIcon,
    iconSize: [44, 40],
    iconAnchor: [10, 15],
    popupAnchor: [0, -13],
  });

  const pIcon = new Icon({
    iconUrl: portIcon,
    iconSize: [22, 20],
    iconAnchor: [10, 15],
    popupAnchor: [0, -13],
  });


  const shenshenLoc = [22.4736986, 114.0565446];
  const hebeiLoc = [38.6189978, 114.6846821, 0];

  return (
    <MapContainer
      style={{ height: "100vh" }}
      center={hebeiLoc}
      zoom={6}
      scrollWheelZoom={true}
      maxZoom={9}
      minZoom={6}
      bounceAtZoomLimits={true}
      maxBoundsViscosity={0.75}
      zoomControl={true}
      maxBounds={[
        [-10, 70],
        [50, 160]
      ]} >
      <TileLayer noWrap={true} {...tilelayer} />

      <Marker
        position={hebeiLoc}
        icon={fIcon}
        apContainer
        style={{ height: "25%" }}
        eventHandlers={{
          mouseover: (event) => event.target.openPopup(),
          // mouseout: (event) => event.target.closePopup()
        }
        }>
        <Popup>
          <img className="imagePopupFactory" src={`https://${imageUrl}`} alt="factory, people working"  />
          <hr className="hrImage"/>
          <p className="productname-text">{productName}</p>
          <p className="category-text">Flokkar: {category}</p>
          <p className="description-text">{description}.</p>
          <div className="countdownText">Pöntun verður send eftir:</div>
          <div ref={myContainer} className="countdownTimer">Niðurtalning</div>
          <p className="price-text"><strong>Verð:</strong> {Number(price).toLocaleString('is')} kr.</p>
          <span className="btn-center">
            <button ref={orderButton} className="btn btn-reverse float-left submit-button" onClick={() => { window.location.href = orderurl }}>Panta</button>
          </span>
        </Popup>
      </Marker>

      <Marker
        position={shenshenLoc}
        icon={pIcon}
        eventHandlers={{
          mouseover: (event) => event.target.openPopup(),
          mouseout: (event) => event.target.closePopup()
        }
        }>
        <Popup>
          <img className="imagePopupFactory" src={"https://container-news.com/wp-content/uploads/2018/12/Port-of-Shenzhen-Port-of-Yantia_Sh2-768x512.jpg"} alt="" srcSet="" />
          <p>Höfnin í Shenshen.</p>
          
        </Popup>

      </Marker>

    </MapContainer>
  )
}
