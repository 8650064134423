import React from 'react'
import Map from '../components/Map'

const Home = () => {
  return (
    <div>
      <Map />
    </div>
  )
}

export default Home
